<template>
  <div>
    <header  style="position: relative;">
      <div style=" margin: 0 auto;">
          <img style="width: 100%;height: 100%;object-fit: contain;" src="../assets/images/jianjie/Group 1968.png" />
      </div>
      
      <div style="position: absolute;top: 150px;left: 300px;">
        <div>
          </div>
      </div>
      <div class="boss-content" style="z-index: 9;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;">
            <div style="position: relative;">
              <div class="boss-content">
                <h1 style="color : white;font-size: 50px;"><b>壹公里</b>&nbsp;&nbsp;|&nbsp;&nbsp;让数据价值生生不息</h1>
                <p style="color : white;font-size: 20px;">客户第一 · 艰苦奋斗 · 诚信务实 · 共享共创</p>
                <br>
                <el-button class="image-button" @click="router()">立即咨询<img src='../assets/images/home_slices/arrowhead.png' style="margin-left: 30px;height: 15px;margin-bottom: 3px"></el-button>
              </div>
            </div>
      </div>
    </header>
    <main class="main">
      <div class="title" style="height: 250px;position: relative;">
        <div style="position: relative;">
          <h1 style="font-size: 40px;"><b>壹公里简介</b></h1>
          <div style="position: absolute;top: -15px;left: -20px; ">
            <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
          </div>
        </div>
      </div>

      <div style="height: 100px;position: relative; " class="boss-content">

        <div style="  margin: 0 auto;position: absolute;bottom: -90px;width: 100%;">

          <el-row :gutter="40">
            <el-col :xs="6" :md="6">
              <div class="grid-content bg-purple" style="
                position: relative;
                text-align: center;
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 180px;
                ">
                <div style="position: absolute;top: -50px;left:60px;">
                  <img style="height:100px;" src="../assets/images/jianjie/Group 1977.png" />
                </div>
                <div style="text-align: center;position: absolute;bottom:30px;left: 50%;transform: translateX(-50%);">
                  <span style="font-size: 40px; color: blue;font-weight: bolder">100+</span><span><b>家</b></span>
                  <h6>上云</h6>
                </div>
              </div>
            </el-col>
            <el-col :xs="6" :md="6">
              <div class="grid-content bg-purple" style="
               display: flex; justify-content: center;
                position: relative;
                text-align: center;
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 180px;
                ">
                <div style="position: absolute;top: -50px;left:60px;">
                  <img style="height:100px;" src="../assets/images/jianjie/Group 1978.png" />
                </div>
                <div style="text-align: center;position: absolute;bottom:30px;left: 50%;transform: translateX(-50%);">
                  <span style="font-size: 40px; color: blue;font-weight: bolder;">24+</span><span><b>家</b></span>
                  <h6>数字车间</h6>
                </div>
              </div>
            </el-col>
            <el-col :xs="6" :md="6">
              <div class="grid-content bg-purple" style="
               display: flex; justify-content: center;
                position: relative;
                text-align: center;
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 180px;
                ">
                <div style="position: absolute;top: -50px;left:60px;">
                  <img style="height:100px;" src="../assets/images/jianjie/Group 1980.png" />
                </div>
                <div style="text-align: center;position: absolute;bottom:30px;left: 50%;transform: translateX(-50%);">
                  <span
                    style="font-size: 40px; color: blue;font-weight: bolder">12+</span><span><b>家</b></span>
                  <h6>数字工厂</h6>
                </div>
              </div>
            </el-col>
            <el-col :xs="4" :md="6">
              <div class="grid-content bg-purple" style="
               display: flex; justify-content: center;
                position: relative;
                text-align: center;
                  box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
                  height: 180px; 
                ">
                <div style="position: absolute;top: -50px;left:60px;">
                  <img style="height:100px;" src="../assets/images/jianjie/Group 1980.png" />
                </div>
                <div style="text-align: center;position: absolute;bottom:30px;left: 50%;transform: translateX(-50%);">
                  <span style="font-size: 40px; color: blue;font-weight: bolder">60+</span><span><b>家</b></span>
                  <h6>解析标识</h6>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="jianjie">

      </div>

      <div style="height: 100px;position: relative; " class="boss-content">
        <div style="background-color: white;  height: 400px; 
          box-shadow: 1px 5px 5px 3px rgba(0.05, 0.05, 0.05, 0.05);
          display: flex;align-items: center;justify-content: center;
          position: absolute;top:-180px;">
          <div style="margin-left: 50px;margin-right: 50px;">
            <p>
              <!-- <b>广东壹公里数智科技有限公司</b>成立于2014年6月18日，是国家高新技术企业，壹公里以<b>“数据决定价值”</b>为理念，致力于企业智能运营，
              运用数据分析、预测、预警、协调、调度、决策和指挥等，提高企业营销、财务、生产制造、采购、人力等部门数智化运营水平，
              帮助老板轻松管理企业。
              <br>
              <br>
              壹公里长期与多家<b>世界500强企业合作研发数据平台</b>，培养了一支技术领先、产品成熟、落地可靠的技术团队。
              在专业水准、组织规模、服务范围、企业客户数量上均为业内前列。
              <br>
              <br>
              目前已入选<b>广东省上云上平台资源池A类供应商、佛山市、南海区、顺德区资源池供应商</b>，
              同时是阿里巴巴集团研发战略伙伴、中国移动企业应用合作伙伴、广东联通企业应用合作伙伴、华为云合作伙伴、佛山市软件行业协会副会长单位和佛山市湖南省隆回县商会副会长单位。
              <br>
              <br>
              2020年评为南海区<b>“腾云计划”</b>重点扶持企业。公司及产品多次获得<b>广东省工信厅、市、区评审认定，并获得优秀软件产品称号</b>。
               -->

              壹公里成立于2014年，专注于数据中台研发，先后评为<b>“国家级高新技术企业”、“广东省专精特新企业”，佛山唯一获得中国信通院“数据中台”和“工业互联网平台”检测认证</b>，并全面通过<b>ISO9001质量管理体系认证及27001信息安全管理体系认证。</b>壹公里以“让数据价值生生不息”的理念为引领，致力于挖掘和释放企业各个部门（包括研发、生产、供应、销售、服务、财务等）的数据潜力，并通过提供数据中台、流程集成、数据决策和低代码等高效生产力软件，助力企业实现全价值链可视化经营。
              <br>
              <b>壹公里</b>长期参与阿里云数据中台研发服务，在数据中台研发服务方面积累了丰富经验。我们在家电、铝材、汽车、机械、家居、五金等行业拥有头部客户的合作经验，这使得我们培养了一支技术领先、产品成熟、落地可靠的服务团队。我们将继续秉承这些优势，持续帮助客户成功。
            </p>
          </div>
        </div>
      </div>





      <div style="height: 500px; margin-top: 250px;" class="boss-content">
        <div style=" margin: 0 auto">
          <el-row :gutter="40">
            <el-col :xs="12" :md="12" style="height: 180px;">
              <div class="myimg" style="
            position: relative;">
                <div style="position: absolute;top: 50px;left: 150px; width: 300px; ">
                  <h3 style="color: white;">企业使命</h3>
                  <p style="color: white;">让数据价值生生不息。</p>
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :md="12" style="height: 180px;">
              <div class="myimg2" style="
            position: relative;">
                <div style="position: absolute;top: 50px;left: 150px; width: 300px; ">
                  <h3 style="color: white;">企业愿景</h3>
                  <p style="color: white;">将数智化带入每个企业，构建没有数据孤岛的智能组织。</p>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :xs="12" :md="12" style="height: 180px;">
              <div class="myimg3" style="
            position: relative;">
                <div style="position: absolute;top: 50px;left: 150px; width: 300px; ">
                  <h3 style="color: white;">企业价值</h3>
                  <p style="color: white;">客户第一、艰苦奋斗、诚信务实、共享共创。</p>
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :md="12" style="height: 180px;">
              <div class="myimg4" style="
            position: relative;">
                <div style="position: absolute;top: 50px;left: 150px; width: 300px; ">
                  <h3 style="color: white;">经营理念</h3>
                  <p style="color: white;">可循环的数据、可持续的价值、更环保低碳的服务。</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="chanping" style="height: 1000px;">
        <div class="boss-content">
          <div class="title" style="height: 250px;position: relative;">
          <div style="position: relative;">
            <h1 style="font-size: 40px;"><b>产品与服务体系</b></h1>
            <div style="position: absolute;top: -15px;left: -20px; ">
              <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
            </div>
          </div>
        </div>
        <div style=" margin: 0 auto;">
          <img style="width: 100%;height: 100%;object-fit: contain;" src="../assets/images/jianjie/pands.png" />
        </div>
        </div>
      </div>
      <div  class="boss-content">
        <div class="title" style="height: 250px;position: relative;">
          <div style="position: relative;">
            <h1 style="font-size: 40px;"><b>壹公里荣誉</b></h1>
            <div style="position: absolute;top: -15px;left: -20px; ">
              <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
            </div>
          </div>
        </div>
        <div style=" margin: 0 auto">
          <img style="width: 100%;" src="../assets/images/ronyu.png" alt="">
          <!-- <el-row :gutter="40">
            <el-col :md="6" v-for="(item, i) in qualification" :key="i" >
              <img style="width: 260px;" :src="item.imagePath ? $imgUrl + item.imagePath : ''" alt="" />
            </el-col>

          </el-row> -->
        </div>
      </div>

      <div class="chanquan">

        <div class="boss-content">
          <div class="title" style="height: 250px;position: relative;">
          <div style="position: relative;">
            <h1 style="font-size: 40px;"><b>知识产权</b></h1>
            <div style="position: absolute;top: -15px;left: -20px; ">
              <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
            </div>
          </div>
        </div>
        <div>
          <div style=" margin: 0 auto">
            <el-row :gutter="40">
              <el-col :xs="12" :md="12" style="">
                <div class="myimg5" style="width: 510px;height: 180px;
            text-align: center;display: flex;justify-content: center;align-items: center;">
                  <h5 style="width: 80%;text-align: center;">一种数据网关插件更新方法、装置、电子设备及存储介质</h5>
                </div>
              </el-col>
              <el-col :xs="12" :md="12" style="">
                <div class="myimg5" style="width: 510px;height: 180px;
            text-align: center;display: flex;justify-content: center;align-items: center;">
                  <h5 style="width: 80%;text-align: center;">一种会议PPT自动生成方法、装置、电子设备及存储介质</h5>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div style=" margin: 0 auto;margin-top: 30px; ">
          <el-row :gutter="40" style="">
            <el-col :xs="8" :md="8"  v-for="(item, i) in product" :key="i">
              <div class="myimg6" style="position: relative;height: 170px;width: 340px;
            text-align: center;display: flex;justify-content: center;">
                <div style="position: absolute;top: 60px;left: 20px; line-height:10px;text-align: center;width: 280px;">
                  <p style="text-align: center;"><b>{{ item.title }}</b></p>
                  <p v-if="item.name !== ''" style="text-align: center;"><b>{{ item.name }}</b></p>
                  <p v-if="item.v !== ''" style="text-align: center;"><b>{{ item.v }}</b></p>

                </div>
                <div style="position: absolute;top: 15px;left: -30px; line-height:10px;text-align: center;width: 200px;">
                  <p style="text-align: center;">软件著作权-{{ i + 1 }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>

        </div>

        
      </div>

      <div class="boss-content">
        <div class="title" style="height: 250px;position: relative;">
          <div style="position: relative;">
            <h1 style="font-size: 40px;"><b>壹公里发展历程</b></h1>
            <div style="position: absolute;top: -15px;left: -20px; ">
              <img style="height: 50px;" src="../assets/images/bossfront/Subtract.png" alt="">
            </div>
          </div>
        </div>
        <div style=" margin: 0 auto;">
          <img style="width: 100%;height: 100%;object-fit: contain;" src="../assets/images/jianjie/Group 2083.png" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  components: {},
  data() {
    return {
      qualification: [],
      product: [
        {
          title: 'BOSS报表平台',
          name: '',
          v: 'V5.0'
        },
        {
          title: '企业经营数据分析平台',
          name: '',
          v: 'V1.0'
        },
        {
          title: 'BossBI数据管理云化应用软件',
          name: '',
          v: 'V5.0'
        },
        {
          title: 'BossBI智造中台云化应用软件',
          name: '',
          v: 'V3.0'
        },
        {
          title: 'BossBI数据中台云化应用软件',
          name: '',
          v: 'V3.0'
        },
        {
          title: 'Boss中台云化应用软件',
          name: '[简称: Boss中台]',
          v: 'V3.0'
        },
        {
          title: '营销中台云化应用软件',
          name: '[简称: Boss中台]',
          v: 'V3.0'
        },
        {
          title: 'BoSSONE工厂应用集成云化应用软件',
          name: '[简称:工厂应用集成云化应用软件]',
          v: 'V3.0'
        },
        {
          title: '财务中台云化应用软件',
          name: '[简称: 财务中台]',
          v: 'V3.0'
        },
        {
          title: '生产中台云化应用软件',
          name: '[简称: 生产中台]',
          v: 'V3.0'
        },
        {
          title: '企业风险检测系统',
          name: '[简称: 风险检测系统]',
          v: 'V2.0'
        },
        {
          title: '企业风险预防和控制系统',
          name: '[简称: 风险预防和控制系统]',
          v: 'V2.0'
        },
        {
          title: 'BossONE云数据仓库平台',
          name: '[简称: 云数据仓库]',
          v: 'V1.0'
        },
        {
          title: '混合云数据魔方软件',
          name: '[简称: 数据魔方]',
          v: 'V1.0'
        },
        {
          title: 'BossONEIPaaS集成云化应用软件',
          name: '[简称: BOSSONEIPaaS集成平台]',
          v: 'V1.0'
        },
        {
          title: 'BossONE Flink大数据平台',
          name: '',
          v: 'V2.0'
        },
        {
          title: 'BossBI大数据管理云化应用软件',
          name: '[简称: :BossBI大数据管理]',
          v: 'V1.0'
        },
      ]

    };
  },
  computed: {},
  watch: {},
  methods: {
    router() {
      this.$router.push("/apply/");
    },

  },
  created() { },
  async mounted() {
    let _this = this;
    let ziji = await this.$http.get("/qualification1");
    if (ziji.status == 200) {
      console.log(ziji.data.data.qualification);
      _this.qualification = ziji.data.data;
    }

    // 动画重启
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.jianjie {
  background: url("../assets/images/jianjie/Group 1972.png");
  background-size: 100%;
  height: 450px;
}

.image-button {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
  width: 180px;
  height: 50px;
  outline: none;
  border: none;
}

.image-button:focus {
  outline: none;
  box-shadow: none;
  outline-color: transparent !important;
  border-color: transparent !important;
}

/* 设置导航栏文字颜色为黑色 */
.navbar .el-menu-item {
  color: black;
}

/* 设置鼠标悬停时文字加粗 */
.navbar .el-menu-item:hover {
  font-weight: bold;
}

.bg-purple {
  background: white;
}

.grid-content {
  min-height: 50px;
}

.header {
  background: url("../assets/images/jianjie/Group 1968.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 700px;
  position: relative;
  z-index: 1;
}

.myimg {
  background-image: url('../assets/images/jianjie/Group 1998.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.myimg2 {
  background-image: url('../assets/images/jianjie/Group 1999.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.myimg3 {
  background-image: url('../assets/images/jianjie/Group 2000.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.myimg4 {
  background-image: url('../assets/images/jianjie/Group 2001.png');
  background-size: 100%;
  height: 100%;
  width: 100%;
}

.myimg5 {
  background-image: url('../assets/images/jianjie/Group 2019.png');
  background-size: 100%;
  height: 236px;
  width: 652px;
  
}

.myimg6 {
  background-image: url('../assets/images/jianjie/Group 2024.png');
  background-size: 100%;
  height: 212px;
  width: 412px;
}


.chanping {
  background: url("../assets/images/jianjie/Rectangle 4559.png");
  background-size: 100%;
}

.chanquan {
  background: url("../assets/images/jianjie/Rectangle 4559.png");
  background-size: 100%;
}

.title {
  height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>